import Home from "@/components/Home";
import Home2 from "@/components/Home2";
import { ABTEST_VERSION_DARKBLUE } from "@/constants";
import { ABTEST_VERSION } from "@cospex/client/abtest";
import useTranslation from "@cospex/client/hooks/useTranslation";

export default function Index() {
  const { t } = useTranslation();
  return ABTEST_VERSION === ABTEST_VERSION_DARKBLUE ? (
    <Home2 title={t("home-title-samsung-s21")} />
  ) : (
    <Home title={t("home-title-samsung-s21")} />
  );
}
